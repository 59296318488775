.vi__container {
  margin-top: 15px;
  justify-content: center;
  height: 50px;
  width: 100%;
}
.vi__character {
  width: 50px;
  border-radius: 25px;
  margin: 0px 2px;
  justify-content: center;
  align-items: center;
  line-height: 45px;
  font-size: 30px;
  text-align: center;
  color: #023e43;
  font-family: "Raleway";
  border-color: #023e43;
  border-width: 2px;
}
.vi__character--inactive {
  background-color: #fff;
}
